// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_sD d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_sF d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_sG d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_sH d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_sJ d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_sK d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_sL d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_rG q_rG";
export var heroExceptionNormal = "s_rH q_rH";
export var heroExceptionLarge = "s_rJ q_rJ";
export var Title1Small = "s_rh q_rh q_qN q_qP";
export var Title1Normal = "s_rj q_rj q_qN q_qQ";
export var Title1Large = "s_rk q_rk q_qN q_qR";
export var BodySmall = "s_rw q_rw q_qN q_q5";
export var BodyNormal = "s_rx q_rx q_qN q_q6";
export var BodyLarge = "s_ry q_ry q_qN q_q7";